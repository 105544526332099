<template>
  <v-container>
    <v-row justify="center" class="mt-3">
      <v-col class="text-center">
        <h4 class="text-h4 font-weight-regular">Match Request</h4>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" xl="8">
        <v-card>
          <div class="grey--text text-center py-15" v-if="loading">
            <v-progress-circular indeterminate size="50" />
            <div class="caption my-2">Loading request...</div>
          </div>
          <v-card-text v-else>
            <v-simple-table v-if="currentMatchRequest">
              <template v-slot:default>
                <tbody>
                  <tr v-if="currentMatchRequest.userId && canShowUserInfo()">
                    <td class="text-right">User ID</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{ name: 'user.view', params: { id: currentMatchRequest.userId } }"
                      >
                        {{ currentMatchRequest.userId || "-" }}
                      </v-btn>
                    </td>
                  </tr>

                  <tr v-for="item in items" :key="item.name">
                    <td class="text-right">{{ item.name }}</td>
                    <td class="text-left">
                      {{ emptyChecker(item.value(currentMatchRequest)) }}
                    </td>
                  </tr>

                  <tr v-if="currentMatchRequest.faceImageId1 && canShowFaceRequest()">
                    <td class="text-right">Face Image 1</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{
                          name: 'request-image.single',
                          params: { md5sum: currentMatchRequest.faceImageId1 },
                        }"
                      >
                        {{ currentMatchRequest.faceImageId1 || "-" }}
                      </v-btn>
                    </td>
                  </tr>

                  <tr v-if="currentMatchRequestFace1">
                    <td class="text-right">Image 1</td>
                    <td class="text-left">
                      <media-image
                        :data="currentMatchRequestFace1.imageBase64"
                        :mime="currentMatchRequestFace1.mimeType"
                      />
                    </td>
                  </tr>
                  <tr v-if="currentMatchRequest.faceImageId2 && canShowFaceRequest()">
                    <td class="text-right">Face Image -2</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{
                          name: 'request-image.single',
                          params: { md5sum: currentMatchRequest.faceImageId2 },
                        }"
                      >
                        {{ currentMatchRequest.faceImageId2 || "-" }}
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="currentMatchRequestFace2">
                    <td class="text-right">Image 2</td>
                    <td class="text-left">
                      <media-image
                        :data="currentMatchRequestFace2.imageBase64"
                        :mime="currentMatchRequestFace2.mimeType"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import MediaImage from "@/views/components/Media";
import { FACE_MATCH_REQUEST_SINGLE, FACE_REQUEST_IMAGE_SINGLE } from "@/constants/api";

export default {
  name: "MatchRequest",
  metaInfo: { title: "Face Match Request" },
  components: { MediaImage },
  data() {
    return {
      loading: false,
      currentMatchRequest: null,
      currentMatchRequestFace1: null,
      currentMatchRequestFace2: null,
      items: [
        { name: "Request Id", value: (item) => item.requestId },
        { name: "Label", value: (item) => item.label },
        { name: "Confidence", value: (item) => Math.round(100 * item.confidence) + "%" },
        { name: "Distance", value: (item) => item.distance },
        { name: "Threshold", value: (item) => item.threshold },
        { name: "Processing Time", value: (item) => item.processingTime },
        {
          name: "Requested At",
          value: (item) => moment(item.createdAt).format("DD MMM, YYYY \n hh:mm:ss A"),
        },
      ],
    };
  },
  mounted() {
    if (this.$route.params.requestId) {
      this.loading = true;
      this.fetchMatchRequest(this.$route.params.requestId)
        .then(this.fetchFaceMatchRequestImage)
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    fetchMatchRequest(requestId) {
      return this.$axios
        .get(`${FACE_MATCH_REQUEST_SINGLE}?requestId=${requestId}`)
        .then((response) => {
          if (response.data.code === 0) {
            this.currentMatchRequest = response.data.data;
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((err) => {
          this.$iziToast.showError(err);
          this.$router.back();
        });
    },
    fetchFaceMatchRequestImage() {
      if (this.currentMatchRequest.faceImageId1) {
        this.$axios
          .get(`${FACE_REQUEST_IMAGE_SINGLE}?md5sum=${this.currentMatchRequest.faceImageId1}`)
          .then((response) => {
            if (response.data.code === 0) {
              this.currentMatchRequestFace1 = response.data.data;
            } else {
              console.warn("Face image 1 not found!");
            }
          });
      }
      if (this.currentMatchRequest.faceImageId2) {
        this.$axios
          .get(`${FACE_REQUEST_IMAGE_SINGLE}?md5sum=${this.currentMatchRequest.faceImageId2}`)
          .then((response) => {
            if (response.data.code === 0) {
              this.currentMatchRequestFace2 = response.data.data;
            } else {
              console.warn("Face image 2 not found!");
            }
          });
      }
    },
    emptyChecker(val, placeholder = "-") {
      return val ? val : placeholder;
    },
  },
};
</script>

<style scoped></style>
